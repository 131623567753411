<template>
   <div>
    <qualityComponent v-if="reportDevice.type === 'WQ'" />
    <rainReportComponent v-else-if="reportDevice.type === 'rain'" />
    <weatherReportComponent v-else-if="reportDevice.type === 'weather'" />
    <levelComponent v-else />
</div>
</template>
<script>
import { mapActions, mapGetters} from 'vuex'
import levelComponent from './levelReportComponent/level'
import rainReportComponent from './rainReportComponent/index'
import weatherReportComponent from './weatherReportComponent/index'
import qualityComponent from './waterQualityComponent/index'
import globalMixin from '../../mixin/global'

var fileDownload = require('js-file-download');
export default {
    data: () => ({
       
    }),
    mixins:[globalMixin],
    methods:{

    
    },
    computed:{
        ...mapGetters({
            reportDevice:'device/reportDevice'
        })
      
    },
    watch:{
      
    },
    components:{
        levelComponent,qualityComponent,rainReportComponent,weatherReportComponent
    },
    async created() {
       
    },
    mounted() {
       
    }
    
}
</script>