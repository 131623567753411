export default {
    data: () => ({
        optionsElevasi:
            {
            series: [{
                name: 'Elevasi Muka Air [m]',
                data: []
            }],
            animations: {
                enabled: true,
                easing: 'linear',
                dynamicAnimation: {
                    speed: 1000
                }
            },
            labels:[],
            chart: {
                type: 'area',
                stacked: false,
                height: 200,
                zoom: {
                    type: 'x',
                    enabled: true,
                    autoScaleYaxis: true
                },
                toolbar: {
                    autoSelected: 'zoom'
                }
            },
            dataLabels: {
                enabled: true,
                formatter: function(value, { series, seriesIndex, dataPointIndex, w }) {
                    return value + ' ' + 'km'
                  },
                style:{
                    fontSize: '10px',
                }

            },
            markers: {
                size: 0,
            },
            title: {
                
                align: 'left'
            },
            colors: ['#34D399'],
            fill: {
                type: 'gradient',
                gradient: {
                    shadeIntensity: 1,
                    inverseColors: false,
                    opacityFrom: 0.9,
                    opacityTo: 0,
                    stops: [0, 90, 100]
                },
            },
            yaxis: {
               
                labels:{
                    formatter: (value) => { return value.toFixed(2) },
                },
                title: {
                    // text: 'h [cm]'
                },
            },
            xaxis: {
                type: 'datetime',
                labels: {
                    datetimeUTC: false
                },

            },
            tooltip: {
                marker: {
                    show: false,
                },
                x: {
                    format: 'dd MMM y',
                    floating: false,
                    },
                    y: {
                        formatter: function(value, { series, seriesIndex, dataPointIndex, w }) {
                          return value + ' ' + 'km'
                        },
                        tickAmount: 2,
                      }
                   
                // },
                // custom: function({series, seriesIndex, dataPointIndex, w}) {
                //     return `<div class="p-4">
                //     <span class="text-green-400 font-bold"> h : ${w.config.series[seriesIndex].data[dataPointIndex].strikeDistance ? w.config.series[seriesIndex].data[dataPointIndex].strikeDistance : ''} km </span>
                      
                //       <br>
                //       <span class="mt-2 text-blue-400 font-bold">Jarak Sambaran Petir : ${series[seriesIndex][dataPointIndex]} km</span>
                    
                //       <br>
                //       </div>`
                // },
                // x: {
                //     // format: 'y-MM-dd HH:mm:ss',
                //     formatter: function(value, { series, seriesIndex, dataPointIndex, w}) {
                //         let height = 0
                //         if (w) {
                //             height = w.config.series[seriesIndex].data[dataPointIndex].h
                            
                //         }
                //         return 'Ketinggian Air : ' + height + ' ' + 'cm'
                //     }
                // },
                // z: {
                //     // format: 'y-MM-dd HH:mm:ss',
                //     formatter: function(value, { series, seriesIndex, dataPointIndex, w }) {
                //         console.log(value)
                //         return value + ' ' + 'm'

                //     },
                //     title: {
                //         formatter: (seriesName) => seriesName,
                //     },
                // },
                //  y: {
                //     formatter: function(value, { series, seriesIndex, dataPointIndex, w }) {
                //       return value + ' ' + 'm'
                //     },
                //     title: {
                //         formatter: (seriesName) => seriesName,
                //     },
                //   }
            },
            
            stroke: {
                show: true,
                curve: 'smooth',
                lineCap: 'round',
                width: 2,
                dashArray: 0,      
            }
        },
    }),
}